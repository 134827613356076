import styled from "styled-components";

import aave from "../assets/partners/aave.svg";
import curve from "../assets/partners/curve.svg";
import uniswap from "../assets/partners/uniswap.svg";
import yearn from "../assets/partners/yearn.svg";
import chainlink from "../assets/partners/chainlink.svg";
import gelato from "../assets/partners/gelato.svg";
import convex from "../assets/partners/convex.svg";
import mero from "../assets/partners/mero.svg";

interface PartnerType {
  name: string;
  logo: string;
  link: string;
}

const partners: PartnerType[] = [
  {
    name: "Chainlink",
    logo: chainlink,
    link: "https://chain.link/",
  },
  {
    name: "Gelato",
    logo: gelato,
    link: "https://www.gelato.network/",
  },
  {
    name: "Curve",
    logo: curve,
    link: "https://curve.fi/",
  },
  {
    name: "Aave",
    logo: aave,
    link: "https://aave.com/",
  },
  {
    name: "Uniswap",
    logo: uniswap,
    link: "https://uniswap.org/",
  },
  {
    name: "Convex",
    logo: convex,
    link: "https://www.convexfinance.com/",
  },
  {
    name: "Yearn Finance",
    logo: yearn,
    link: "https://yearn.finance/",
  },
  {
    name: "Mero",
    logo: mero,
    link: "https://mero.finance/",
  },
];

const StyledPartnerships = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  padding: 10rem 0;
  @media (max-width: 600px) {
    padding: 8rem 4rem;
  }
`;

const Header = styled.h4`
  position: relative;
  font-weight: 600;
  color: var(--main);
  max-width: 40rem;
  text-align: center;

  font-size: 3.2rem;
  font-size: 3.2rem;
  line-height: 4.3rem;
  @media (max-width: 600px) {
    font-size: 2.6rem;
    line-height: 3.8rem;
  }
`;

const Partners = styled.div`
  width: 100%;
  max-width: 105rem;
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  grid-gap: 4.3rem;
  margin-top: 7rem;
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4rem;
    margin-top: 5rem;
  }
`;

const PartnerLink = styled.a`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Partner = styled.img`
  max-width: 100%;
  max-height: 6.7rem;
`;

const Partnerships = () => {
  return (
    <StyledPartnerships>
      <Header>Utilise time trusted defi legos or add your own</Header>
      <Partners>
        {partners.map((p, i) => (
          <PartnerLink key={i} href={p.link} target="_blank">
            <Partner src={p.logo} alt={`${p.name} Logo`} />
          </PartnerLink>
        ))}
      </Partners>
    </StyledPartnerships>
  );
};

export default Partnerships;
