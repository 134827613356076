import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";
import Layout from "./Layout";
import LandingPage from "./pages/LandingPage";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { protocolsState, tokensState } from "./recoil/atoms";
import fetchTokens from "./lib/get-tokens";
import fetchProtocols from "./lib/get-protocols";
// import CreatePage from "./pages/CreatePage";
// import ManagePage from "./pages/ManagePage";

const App = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tokens, setTokens] = useRecoilState(tokensState);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [protocols, setProtocols] = useRecoilState(protocolsState);

  const updateTokens = async () => setTokens(await fetchTokens());
  const updateProtocols = async () => setProtocols(await fetchProtocols());

  useEffect(() => {
    updateTokens();
    updateProtocols();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<LandingPage />} />
            {/* <Route path="manage" element={<ManagePage />} />
            <Route path="create" element={<CreatePage />} /> */}
            <Route path="*" element={<LandingPage />} />
          </Route>
        </Routes>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
