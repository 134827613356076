import { useFeeData } from "wagmi";
import { bigNumberToNumber } from "../number";

const useGasPrice = (): number | null => {
  const { data, isError, isLoading } = useFeeData();
  if (isError) return null;
  if (isLoading) return null;
  if (!data) return null;
  if (!data.gasPrice) return null;
  return bigNumberToNumber(data.gasPrice, 9);
};

export default useGasPrice;
