import styled from "styled-components";
import Benefits from "../components/Benefits";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import HowDoesItWork from "../components/HowDoesItWork";
import Partnerships from "../components/Partnerships";

const StyledLandingPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LandingPage = () => {
  return (
    <StyledLandingPage>
      <Hero />
      <HowDoesItWork />
      <Benefits />
      <Partnerships />
      <Footer />
    </StyledLandingPage>
  );
};

export default LandingPage;
