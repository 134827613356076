import styled from "styled-components";
import Connector from "./Connector";
import Gas from "./Gas";
import Logo from "./Logo";
// import NavItems from "./NavItems";
import Socials from "./Socials";

const StyledHeader = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 7.8rem;
  padding: 0 9rem;
  background: var(--panel);

  @media (max-width: 600px) {
    padding: 0 2rem;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const Header = () => {
  return (
    <StyledHeader>
      <Logo />
      <RightSection>
        <Socials />
        {/* <NavItems /> */}
        <Gas />
        <Connector />
      </RightSection>
    </StyledHeader>
  );
};

export default Header;
