import { Outlet } from "react-router-dom";
import { WagmiConfig, createClient } from "wagmi";
import styled from "styled-components";

import Header from "./components/Header";
import { getDefaultProvider } from "ethers";

const StyledApp = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--bg);
`;

const client = createClient({
  autoConnect: false,
  provider: getDefaultProvider(),
});

const Layout = (): JSX.Element => {
  return (
    <WagmiConfig client={client}>
      <StyledApp>
        <Header />
        <Outlet />
      </StyledApp>
    </WagmiConfig>
  );
};

export default Layout;
