import { ethToken, manualTokens, TOKENLIST_URL } from "../app/constants";

export interface Token {
  symbol: string;
  url: string;
  name: string;
}

interface Response {
  address: string;
  chainId: number;
  decimals: number;
  logoURI: string;
  name: string;
  symbol: string;
}

const fetchTokens = async () => {
  try {
    const response = await fetch(TOKENLIST_URL);
    const json = await response.json();
    const tokens = json.tokens.map((token: Response): Token => {
      return {
        symbol: token.symbol,
        url: token.logoURI,
        name: token.name,
      };
    });
    return [ethToken, ...tokens, ...manualTokens];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export default fetchTokens;
