import { atom } from "recoil";
import { ProtocolType } from "../lib/get-protocols";
import { Token } from "../lib/get-tokens";

const exampleAtom = atom({
  key: "exampleAtom", // unique ID (with respect to other atoms/selectors)
  default: 2, // default value (aka initial value)
});

const exampleAtomTwo = atom({
  key: "exampleAtomTwo", // unique ID (with respect to other atoms/selectors)
  default: -1, // default value (aka initial value)
});

const displayedUserMainStats = atom({
  key: "displayedUserMainStats",
  default: ["Deposits", "Earnings", "APR", "Total strategies"],
});

const depositUser = atom({
  key: "depositValueUser",
  default: 0,
});

const earningsUser = atom({
  key: "earningsUser",
  default: 0,
});

const aprUser = atom({
  key: "aprUser",
  default: 0,
});

const totalStrategies = atom({
  key: "totalStrategies",
  default: 0,
});

const tokensState = atom<Token[]>({
  key: "tokens",
  default: [],
});

const protocolsState = atom<ProtocolType[]>({
  key: "protocols",
  default: [],
});

export {
  exampleAtom,
  exampleAtomTwo,
  displayedUserMainStats,
  depositUser,
  earningsUser,
  aprUser,
  totalStrategies,
  tokensState,
  protocolsState,
};
