import styled from "styled-components";
import { StrategyType } from "../views/strategies";
import Button from "./Button";
import Protocol from "./Protocol";
import StrategyIcon from "./StrategyIcon";
import Token from "./Token";

const StyledStrategy = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 6.9rem;
  padding: 0 2.4rem;

  @media (max-width: 600px) {
    height: 30px;
    > div:nth-child(3) {
      display: none;
    }
    > div:nth-child(2) {
      order: 1;
    }
    > div:nth-child(4) {
      display: none;
    }
  }

  button {
    @media (max-width: 600px) {
      display: none;
    }
  }
`;

const Value = styled.div`
  flex: 1;
  font-size: 2.1rem;
  font-weight: 500;

  @media (max-width: 600px) {
    font-size: 1.2rem;
    font-weight: 600;
  }
`;

const Container = styled.div`
  flex: 1;
`;

const SmallValue = styled.div`
  position: relative;
  flex: 0.5;

  @media (max-width: 600px) {
    display: none;
  }
`;

const roundToDp = (value: number, dp: number) => {
  return Math.round(value * 10 ** dp) / 10 ** dp;
};

const formatPercent = (value: number) => {
  return `${roundToDp(value, 2)}%`;
};

interface Props {
  strategy: StrategyType;
}

const Strategy = ({ strategy }: Props) => {
  return (
    <StyledStrategy>
      <SmallValue>
        <StrategyIcon strategy={strategy} />
      </SmallValue>
      <Value>{formatPercent(strategy.netApy)}</Value>
      <Protocol protocol={strategy.collateral.protocol} />
      <Container>
        <Token symbol={strategy.debt.symbol} />
      </Container>
      <Protocol protocol={strategy.yield.protocol} />
      <Container>
        <Token symbol={strategy.yield.symbol} />
      </Container>
      <Button
        disabled
        action={() => {
          console.log("meow");
        }}
      >
        Coming Soon
      </Button>
      {/* <Button
        action={() => {
          console.log("meow");
        }}
      >
        View
      </Button> */}
    </StyledStrategy>
  );
};

export default Strategy;
