import { Token } from "../lib/get-tokens";

export const DEFI_LAMA_YIELDS_URL = "https://yields.llama.fi/pools";
export const DEFI_LAMA_PROTOCOLS_URL = "https://api.llama.fi/protocols";
export const TOKENLIST_URL = "https://tokens.coingecko.com/uniswap/all.json";

// Links
export const DOCS_LINK = null;
export const TWITTER_LINK = "https://twitter.com/Arkitect_xyz";
export const GITHUB_LINK = "https://github.com/arkitect-protocol";
export const DISCORD_LINK = "";

export const ethToken: Token = {
  symbol: "ETH",
  url: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
  name: "Ethereum",
};

export const manualTokens: Token[] = [
  {
    symbol: "bveCVX",
    url: "https://app.badger.com/assets/icons/bvecvx.svg",
    name: "Badger Vested Escrow Convex Token",
  },
];
