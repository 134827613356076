// import { Link } from "react-router-dom";
import styled from "styled-components";
import { DOCS_LINK, TWITTER_LINK } from "../app/constants";

import background from "../assets/backgrounds/footer.svg";
import logo from "../assets/logo/full-logo-white.svg";
import Socials from "./Socials";

const StyledFooter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const GetStartedSection = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  padding: 7.5rem 0;
  @media (max-width: 600px) {
    padding: 5rem 0;
  }
`;

const Background = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
`;

const Buttons = styled.div`
  position: relative;
  display: flex;
`;

const Button = styled.button`
  position: relative;
  cursor: pointer;
  font-weight: 500;

  font-size: 1.5rem;
  height: 6.4rem;
  padding: 0 8rem;
  letter-spacing: 0.46000000834465027px;
  @media (max-width: 600px) {
    font-size: 1.3rem;
    height: 5rem;
    padding: 0 6rem;
    letter-spacing: 0.46000000834465027px;
  }
`;

const GetStartedButton = styled(Button)`
  background: var(--bg);
  color: var(--primary);
  box-shadow: 4px 4px 0px #3b52c9;
`;

const ViewDocsButton = styled(Button)`
  color: var(--bg);
`;

const MainFooter = styled.div`
  width: 100%;
  background: #111839;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 14.4rem;
  padding: 0 13.5rem;
  @media (max-width: 600px) {
    height: 10rem;
    padding: 0 4rem;
  }
`;

const Logo = styled.img`
  width: 15.8rem;
`;

const Footer = () => {
  return (
    <StyledFooter>
      <GetStartedSection>
        <Background src={background} alt="Footer background" />
        <Buttons>
          <a href={TWITTER_LINK} target="_blank" rel="noreferrer">
            <GetStartedButton>Follow Us</GetStartedButton>
          </a>{" "}
          {/* <Link to="create">
            <GetStartedButton>Get started</GetStartedButton>
          </Link> */}
          {DOCS_LINK && (
            <a href={DOCS_LINK} target="_blank" rel="noreferrer">
              <ViewDocsButton>View the Docs</ViewDocsButton>
            </a>
          )}
        </Buttons>
      </GetStartedSection>
      <MainFooter>
        <Logo src={logo} alt="Arkitect Logo white" />
        <Socials white />
      </MainFooter>
    </StyledFooter>
  );
};

export default Footer;
