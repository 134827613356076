// import { Link } from "react-router-dom";
import styled from "styled-components";
import { TWITTER_LINK } from "../app/constants";

import background from "../assets/backgrounds/hero.svg";
import Strategies from "./Strategies";

const StyledHero = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
`;

const BackgroundImage = styled.img`
  background: var(--primary);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  min-width: 100%;
  min-height: 73rem;

  @media (max-width: 600px) {
    margin-right: 0;
    left: -12rem;
    transform: none;
    opacity: 1;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 0 4rem;
  @media (max-width: 600px) {
    padding: 0 2rem;
  }
`;

const Header = styled.h1`
  font-weight: 700;
  max-width: 70rem;
  text-align: center;
  color: var(--bg);
  margin-top: 9.3rem;

  font-size: 6.4rem;
  line-height: 7.9rem;
  @media (max-width: 600px) {
    font-size: 4.4rem;
    line-height: 5.9rem;
  }
`;

const Subheader = styled.h2`
  font-weight: 400;
  color: var(--bg);
  margin-top: 1.7rem;
  max-width: 50rem;
  text-align: center;

  font-size: 2.2rem;
  line-height: 3.4rem;
  @media (max-width: 600px) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg);
  color: var(--primary);
  box-shadow: 4px 4px 0px #3b52c9;
  font-weight: 500;
  cursor: pointer;

  height: 7.2rem;
  padding: 0 8rem;
  font-size: 2.2rem;
  margin-top: 4.2rem;
  @media (max-width: 600px) {
    height: 5rem;
    padding: 0 6rem;
    font-size: 1.8rem;
    margin-top: 3.3rem;
  }
`;

const Hero = () => {
  return (
    <StyledHero>
      <BackgroundImage src={background} alt="Arkitect hero background" />
      <Content>
        <Header>The best yields using your favorite tokens</Header>
        <Subheader>
          Deposit collateral, borrow a token and farm some of defi’s highest
          yields in a few clicks
        </Subheader>
        <a href={TWITTER_LINK} target="_blank" rel="noreferrer">
          <Button>Follow Us</Button>
        </a>
        {/* <Link to="create">
          <Button>Get started</Button>
        </Link> */}
        <Strategies />
      </Content>
    </StyledHero>
  );
};

export default Hero;
