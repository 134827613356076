import { selector, selectorFamily } from "recoil";

import {
  exampleAtom,
  exampleAtomTwo,
  depositUser,
  earningsUser,
  aprUser,
  totalStrategies,
  tokensState,
  protocolsState,
} from "./atoms";

const exampleSelector = selector({
  key: "exampleSelector",
  get: ({ get }) => {
    const exAtomOne = get(exampleAtom);
    const exAtomTwo = get(exampleAtomTwo);

    const sum = exAtomOne + exAtomTwo;

    if (sum >= 0) {
      return "Values overall positive";
    } else {
      return "Values overall negative";
    }
  },
});

const displayUserMainStatsSelector = selector({
  key: "displayUserMainStatsSelector",
  get: ({ get }) => {
    const deposits = get(depositUser);
    const earnings = get(earningsUser);
    const apr = get(aprUser);
    const strategies = get(totalStrategies);
    return [
      {
        title: "Deposits",
        value: deposits,
      },
      {
        title: "Earnings",
        value: earnings,
      },
      {
        title: "APR",
        value: apr,
      },
      {
        title: "Total strategies",
        value: strategies,
      },
    ];
  },
});

const tokenSelector = selectorFamily({
  key: "tokenSelector",
  get:
    (symbol: string) =>
    ({ get }) => {
      const tokens = get(tokensState);
      return (
        tokens.find((p) => p.symbol.toLowerCase() === symbol.toLowerCase()) ||
        null
      );
    },
});

const protocolsSelector = selectorFamily({
  key: "protocolsSelector",
  get:
    (id: string) =>
    ({ get }) => {
      const protocols = get(protocolsState);
      return protocols.find((p) => p.id === id) || null;
    },
});

export {
  exampleSelector,
  displayUserMainStatsSelector,
  tokenSelector,
  protocolsSelector,
};
