import { Link } from "react-router-dom";
import styled from "styled-components";

import logo from "../assets/logo/full-logo.svg";

const Image = styled.img`
  width: 15.8rem;
`;

const Logo = () => {
  return (
    <Link to="/">
      <Image src={logo} alt="Arkitect logo" />
    </Link>
  );
};

export default Logo;
