import styled from "styled-components";

import background from "../assets/backgrounds/how-it-works.svg";

const steps: string[] = [
  "Your collateral is deposited into your chosen lending protocol",
  "Your chosen Debt Token is borrowed at a defined LTV",
  "This Debt Token is deposited into a chosen Yield Protocol",
  "Rewards can be claimed manually or autocompounded",
];

const StyledHowDoesItWork = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  padding: 10rem 4rem;
  @media (max-width: 600px) {
    padding: 8rem 4rem;
  }
`;

const Background = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
`;

const Header = styled.h4`
  position: relative;
  font-weight: 600;
  color: var(--bg);
  max-width: 37rem;
  text-align: center;

  font-size: 3.2rem;
  line-height: 4.3rem;
  @media (max-width: 600px) {
    font-size: 2.6rem;
    line-height: 4rem;
  }
`;

const Steps = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  margin-top: 2.4rem;
  max-width: 130rem;

  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.9rem;
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2.4rem;
  }
`;

const Step = styled.div`
  position: relative;
  background: var(--bg);
  height: 100%;
  width: 100%;
  text-align: center;
  border: 1px dashed var(--border);
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2rem 2.4rem;
  font-size: 2rem;
  line-height: 2.8rem;
  @media (max-width: 600px) {
    padding: 1.6rem 2rem;
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
`;

const HowDoesItWork = () => {
  return (
    <StyledHowDoesItWork>
      <Background src={background} alt="How it works background" />
      <Header>What’s going on behind the scenes?</Header>
      <Steps>
        {steps.map((step, index) => (
          <Step key={index}>{step}</Step>
        ))}
      </Steps>
    </StyledHowDoesItWork>
  );
};

export default HowDoesItWork;
