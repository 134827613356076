import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { protocolsSelector } from "../recoil/selectors";

const StyledProtocol = styled.div`
  flex: 1;
  font-size: 2.1rem;
  font-weight: 400;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    font-weight: 500;
    font-size: 1.2rem;
  }
`;

interface Props {
  protocol: string;
}

const Protocol = ({ protocol }: Props) => {
  const p = useRecoilValue(protocolsSelector(protocol));

  return <StyledProtocol>{p?.name || protocol}</StyledProtocol>;
};

export default Protocol;
