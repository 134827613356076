import { useState } from "react";
import styled from "styled-components";
// import { supportedProtocols, supportedYield } from "../config/supported";
import useStrategies, { StrategyType } from "../views/strategies";
import Strategy from "./Strategy";

const StyledStrategies = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 150rem;

  margin-top: 6.6rem;
  margin-bottom: 13rem;
  @media (max-width: 600px) {
    margin-top: 5.5rem;
    margin-bottom: 8rem;
  }
`;

const TokenSelector = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    width: 100%;
    justify-content: space-between;
  }
`;

interface TokenOptionProps {
  active: boolean;
}

const TokenOption = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  height: 4.2rem;
  padding: 0 5rem;
  border: 1px solid var(--bg);
  margin: 0 0.6rem;

  color: ${(props: TokenOptionProps) =>
    props.active ? "var(--primary)" : "var(--bg)"};
  background: ${(props: TokenOptionProps) =>
    props.active ? "var(--bg)" : "rgba(255, 255, 255, 0.2)"};

  @media (max-width: 600px) {
    padding: 0;
    flex: 1;
  }
`;

const Table = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: var(--bg);
  z-index: 100;

  > div:nth-child(2n) {
    background: #fbfbfb;
  }
`;

const Pages = styled.div`
  display: flex;
  align-items: center;
`;

const Previus = styled.button`
  color: var(--sub);
  font-size: 2rem;
  font-weight: 500;
  cursor: pointer;
`;

const Page = styled.div`
  padding: 0.5rem 1rem;
  color: var(--sub);
  font-size: 1.2rem;
  font-weight: 500;
`;

const Next = styled.button`
  color: var(--sub);
  font-size: 2rem;
  font-weight: 500;
  cursor: pointer;
`;

const Headers = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 3.2rem;
  margin-bottom: 1rem;
  padding: 0 2.4rem;

  @media (max-width: 600px) {
    > div:nth-child(3) {
      display: none;
    }
    > div:nth-child(2) {
      order: 1;
    }
    > div:nth-child(4) {
      display: none;
    }
  }
`;

const Header = styled.div`
  flex: 1;
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--bg);

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

const SmallHeader = styled.div`
  flex: 0.5;

  @media (max-width: 600px) {
    display: none;
  }
`;

const HeaderEnd = styled.div`
  height: 1rem;
  width: 15rem;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Strategies = () => {
  const [token, setToken] = useState("USDC");
  const strategies = useStrategies(token);
  const [page, setPage] = useState(0);

  const rowsPerPage = 10;
  const maxPages = Math.ceil(strategies.length / rowsPerPage);

  return (
    <StyledStrategies>
      <TokenSelector>
        <TokenOption onClick={() => setToken("USDC")} active={token === "USDC"}>
          USDC
        </TokenOption>
        <TokenOption onClick={() => setToken("ETH")} active={token === "ETH"}>
          ETH
        </TokenOption>
        <TokenOption onClick={() => setToken("WBTC")} active={token === "WBTC"}>
          WBTC
        </TokenOption>
      </TokenSelector>
      <Headers>
        <SmallHeader />
        <Header>Net APY</Header>
        <Header>Lending Protocol</Header>
        <Header>Debt Token</Header>
        <Header>Yield Protocol</Header>
        <Header>Yield Token</Header>
        <HeaderEnd />
      </Headers>
      <Table>
        {strategies
          // .filter(
          //   (s: StrategyType) =>
          //     supportedProtocols.includes(s.collateral.protocol) &&
          //     supportedYield.includes(s.yield.protocol)
          // )
          .sort((a: StrategyType, b: StrategyType) => b.netApy - a.netApy)
          .slice(rowsPerPage * page, rowsPerPage * (page + 1))
          .map((strategy: StrategyType, index: number) => (
            <Strategy strategy={strategy} key={index} />
          ))}
      </Table>
      <Pages>
        <Previus
          onClick={() => {
            if (page > 0) {
              setPage(page - 1);
            }
          }}
        >
          {"<"}
        </Previus>
        <Page>{`${page + 1}/${maxPages}`}</Page>
        <Next
          onClick={() => {
            if (page < maxPages - 1) {
              setPage(page + 1);
            }
          }}
        >
          {">"}
        </Next>
      </Pages>
    </StyledStrategies>
  );
};

export default Strategies;
