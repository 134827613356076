import styled from "styled-components";

import gas from "../assets/gas.svg";
import useGasPrice from "../lib/hooks/use-gas-price";

const StyledGas = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.6rem;
  background: #e5e8f6;
  height: 3.2rem;
  border-radius: 1.6rem;
  padding: 0 1.4rem;

  font-size: 1.5rem;
  font-weight: 500;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Image = styled.img`
  height: 1.4rem;
  margin-right: 0.6rem;
`;

const Gas = () => {
  const gasPrice = useGasPrice();

  if (!gasPrice) return null;

  return (
    <StyledGas>
      <Image src={gas} alt="gas" />
      {gasPrice}
    </StyledGas>
  );
};

export default Gas;
