import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --bg: white;
        --main: #002237;
        --sub: grey;
        --panel: #FDFDFD;
        --border: #d9d9e1;
        --focus-border: #dadefb;
        --primary: #4660ED;
        --primary-dark: #222F73;
        --gradient: radial-gradient(circle, var(--primary), var(--primary-dark));
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-size: 10px;
        color: var(--main);
        font-family: 'Poppins', sans-serif;
    }

    button {
        background: none;
        border: none;
        outline: none;
    }
    
    input {
        border: none;
        outline: none;

        ::-webkit-outer-spin-button {
            display: none;
        }
        ::-webkit-inner-spin-button {
            display: none;
        }
    }

    a {
        text-decoration: none;
    }
`;

const GlobalStyles = (): JSX.Element => {
  return <GlobalStyle />;
};

export default GlobalStyles;
