import styled from "styled-components";

interface ButtonProps {
  primary: boolean;
  sub: boolean;
  disabled: boolean;
}

const StyledButton = styled.button`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  height: 4.8rem;
  padding: 0 1.5rem;
  min-width: 15rem;

  opacity: ${(props: ButtonProps) => (props.disabled ? 0.4 : 1)};
  cursor: ${(props: ButtonProps) =>
    props.disabled ? "not-allowed" : "pointer"};
  border: solid 1px
    ${(props: ButtonProps) => (props.sub ? "transparent" : "var(--primary)")};
  color: ${(props: ButtonProps) =>
    props.primary ? "var(--bg)" : "var(--primary)"};
  background: ${(props: ButtonProps) =>
    props.primary ? "var(--primary)" : "var(--bg)"};
`;

interface Props {
  primary?: boolean;
  sub?: boolean;
  disabled?: boolean;
  action: () => void;
  children: React.ReactNode;
}

const Button = ({ action, sub, primary, children, disabled }: Props) => {
  return (
    <StyledButton
      disabled={!!disabled}
      sub={!!sub}
      primary={!!primary}
      onClick={() => {
        if (!disabled) action();
      }}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
