import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { tokenSelector } from "../recoil/selectors";

const StyledToken = styled.div`
  flex: 1;
  font-size: 2.1rem;
  font-weight: 400;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    font-weight: 500;
    font-size: 1.2rem;
  }
`;

const Icon = styled.img`
  height: 2.2rem;
  margin-right: 0.6rem;
  border-radius: 50%;

  @media (max-width: 600px) {
    display: none;
  }
`;

interface Props {
  symbol: string;
}

const Token = ({ symbol }: Props) => {
  const token = useRecoilValue(tokenSelector(symbol));

  return (
    <StyledToken>
      {token && <Icon src={token?.url} alt={`${token?.symbol} logo`} />}
      {token?.symbol || symbol}
    </StyledToken>
  );
};

export default Token;
