import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { protocolsSelector, tokenSelector } from "../recoil/selectors";
import { StrategyType } from "../views/strategies";

const StyledStrategyIcon = styled.div`
  display: flex;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 5.8rem;
  height: 5.8rem;
`;

const Circle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70%;
  width: 70%;
  border-radius: 50%;
  border: solid 2px var(--border);
`;

const Icon = styled.img`
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 50%;
  background: var(--border);
`;

const Left = styled(Icon)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
`;

const Right = styled(Icon)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
`;

const Top = styled(Icon)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
`;

const Bottom = styled(Icon)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
`;

interface Props {
  strategy: StrategyType;
}

const StrategyIcon = ({ strategy }: Props) => {
  const lending = useRecoilValue(
    protocolsSelector(strategy.collateral.protocol)
  );
  const yieldProtocol = useRecoilValue(
    protocolsSelector(strategy.yield.protocol)
  );
  const collateral = useRecoilValue(tokenSelector(strategy.collateral.symbol));
  const yieldToken = useRecoilValue(tokenSelector(strategy.yield.symbol));

  return (
    <StyledStrategyIcon>
      <Circle />
      <Left src={collateral?.url || ""} />
      <Top src={lending?.logo || ""} />
      <Right src={yieldToken?.url || ""} />
      <Bottom src={yieldProtocol?.logo || ""} />
    </StyledStrategyIcon>
  );
};

export default StrategyIcon;
